import _ from 'underscore';
import AppDispatcher from '../dispatcher/AppDispatcher';
import createStore from '../mixins/createStore';
import DataStore from './DataStore';
import NavActions from '../actions/NavActions';
import Route from '../router/Route';
import router from '../router/router';
import regflow from '../utils/regflow';
import {
  CHANGE_EMAIL,
  CHANGE_PASSWORD,
  CHANGE_USERNAME,
  FORGOT_PASSWORD,
  GROUP_ADD,
  MEMBER_ADD,
  MEMBER_CREATE,
  SIGN_IN,
  SSO_SIGN_IN,
  SSO_CALLBACK,
  REGISTER,
  RESET_PASSWORD,
  SET_EMAIL,
  USER_IMPORT_FORM_ID,
} from '../constants/FormIds';
import {
  GOT_XSSSO_GROUP,
  FORM_SUCCESS,
  APP_INITIALIZED,
  ROUTER_INITIALIZED,
  SITE_LOADED,
  URL_CHANGED,
  URL_CHANGE_REQUESTED,
} from '../constants/ActionTypes';

let _initialized = false;
let _routes = {};
let _currentRoute;
let _currentRouteArgs;

const AppStore = createStore({
  currentRoute() {
    return {
      route: _currentRoute,
      args: _currentRouteArgs,
    };
  },

  routes() {
    return _routes;
  },

  init() {
    const site = DataStore.currentSite();
    if (!site) {
      _initialized = false;
      return;
    }

    const urls = site.urls();
    _routes = {};
    Object.keys(urls).forEach((name) => {
      _routes[name] = new Route(name, urls[name], NavActions.urlChange);
    });
    _initialized = true;
    if (_currentRoute) {
      // Make sure _currentRoute always points to most recent route object
      _currentRoute = _routes[_currentRoute.name];
    }
    const xsSSOGroup = site.get('xssso_group');
    if ((_.isObject(xsSSOGroup)) &&
      (typeof xsSSOGroup.id === 'string') &&
      (xsSSOGroup.id.indexOf('xsg_') === 0)) {
      // The xsSSO must be configured before router.init,
      // otherwise in case of LOGOUT route, router will trigger logout flow
      // before we have info about xsSSO and this will prevent deletion
      // of xCookies from iframe
      //
      // This happens when LOGOUT route is absolute url
      AppDispatcher.handleNavAction({
        actionType: GOT_XSSSO_GROUP, xsSSOGroup,
      });
    }
    router.init(_routes, site);
    AppDispatcher.handleNavAction({ actionType: ROUTER_INITIALIZED });
    AppDispatcher.handleAppAction({ actionType: APP_INITIALIZED, site });
  },

  initialized() {
    return _initialized;
  },

});

AppStore.dispatchToken = AppDispatcher.register((payload) => {
  const action = payload.action;
  const options = action.options || {};

  AppDispatcher.waitFor([DataStore.dispatchToken]);

  function onFormSuccess(formId) {
    switch (formId) {
      case SIGN_IN:
      case SSO_SIGN_IN:
      case SSO_CALLBACK:
        router.navigate(options._nextUrl);
        break;

      case REGISTER:
        regflow.start();
        router.navigate(options._nextUrl);
        break;

      case FORGOT_PASSWORD:
        if (options._nextUrl) {
          router.navigate(options._nextUrl);
        }
        break;

      case RESET_PASSWORD: {
        const nextUrlIsNotHomePage = options._nextUrl !== _routes.HOME.url;
        router.navigate(
          (nextUrlIsNotHomePage && options._nextUrl) ||
          _routes.PASSWORD_RESET_DONE,
        );
        break;
      }

      case CHANGE_EMAIL:
        router.navigate(_routes.EMAIL_CHANGE_DONE);
        break;

      case CHANGE_PASSWORD:
        router.navigate(_routes.PASSWORD_CHANGE_DONE);
        break;

      case CHANGE_USERNAME:
        router.navigate(_routes.USERNAME_CHANGE_DONE);
        break;

      case MEMBER_ADD:
      case MEMBER_CREATE:
        // response.id is member id
        router.navigate(_routes.MEMBER, action.response.id);
        break;

      case GROUP_ADD:
        // response.id is group id
        router.navigate(_routes.GROUP, action.response.id);
        break;

      case USER_IMPORT_FORM_ID:
        // response.id is user import id
        router.navigate(_routes.IMPORT, action.response.id);
        break;

      case SET_EMAIL:
        router.navigate(_routes.EXPERIENCE);
        break;

      default:
    }
  }

  switch (action.actionType) {
    case URL_CHANGED:
      _currentRoute = action.route;
      _currentRouteArgs = action.args;
      break;

    case URL_CHANGE_REQUESTED:
      router.navigate(action.nextUrl);
      break;

    case FORM_SUCCESS:
      onFormSuccess(action.form);
      break;

    case SITE_LOADED:
      // _.defer() is used here because AppStore.init() triggers router
      // initialization, and router may trigger URL_CHANGED action, which
      // may cause 'Dispatch in the middle of dispatch...' error
      _.defer(AppStore.init);
      break;

    case ROUTER_INITIALIZED:
      // Just emit change. This is needed for cases when router didn't
      // detected any known route, and therefore didn't triggered URL_CHANGED
      // action. We might have components which wait for routes to be defined,
      // and we need to let them know that routes are ready, even if current
      // route is undefined.
      break;

    default:
      return true;
  }

  AppStore.emitChange();
  return true;
});

export default AppStore;
