import React from 'react';
import createForm from '../../../mixins/createForm';
import DataStore from '../../../stores/DataStore';
import UserImportActions from '../../../actions/UserImportActions';
import LabeledFileControl from '../../fields/LabeledFileControl';
import Loading from '../../basic/Loading';
import router from '../../../router/router';
import { USER_IMPORT_FORM_ID } from '../../../constants/FormIds';
import Button from '../../basic/Button';
import Form from '../../basic/Form';
import LabeledCombobox from '../../fields/LabeledCombobox';
import LabeledTextarea from '../../fields/LabeledTextarea';
import ImportSummaryPreview from './ImportSummaryPreview';
import PreviewEmail from './PreviewEmail';
import ImportFileFormat from './ImportFileFormat';

const DO_NOT_SEND_EMAILS = 0;
const ONLY_NEW_USERS_EMAILS = 1;
const ALL_NOT_LOGGED_IN_USERS_EMAILS = 2;

const UserImportForm = createForm(USER_IMPORT_FORM_ID, {
  getInitialState() {
    return {
      source: '',
      sendEmailsTo: undefined,
      emailIntroductionPart: '',
      emailPreendPart: '',
      loading: false,
      loadError: '',
    };
  },

  componentWillMount() {
    this.loadUserImport({});
    this.timerId = setInterval(() => {
      const { userImport } = this.state;
      if (userImport && userImport.isRunning()) {
        this.loadUserImport({});
      }
    }, 5000);
  },

  componentWillUnmount() {
    clearInterval(this.timerId);
  },

  componentDidUpdate(prevProps) {
    this.loadUserImport(prevProps);
  },

  loadUserImport(prevProps) {
    if (this.props.importId && prevProps.importId !== this.props.importId) {
      this.setState({ loading: true, loadError: '' });
      UserImportActions.getUserImport(
        this.props.importId,
        () => this.setState({ loading: false }),
        () => this.setState({
          loading: false,
          loadError:
            'Some error happened, try again later or contact administrator'
        }),
      );
    }
  },

  formStateFromStores(formState) {
    const userImport = DataStore.userimport.get(this.props.importId);
    if (userImport) {
      return {
        userImport,
        source: userImport ? userImport.get('source') : '',
        sendEmailsTo: userImport ? userImport.get('send_emails_to') : '',
        emailIntroductionPart: userImport ?
          userImport.get('email_introduction_part') : '',
        emailPreendPart: userImport ? userImport.get('email_preend_part') : '',
      };
    }
    return {};
  },

  onSendEmailsToChange(value) {
    value.target.value = value.target.value.value;
    this.onInputChange(value);
  },

  submit() {
    const isEdit = Boolean(this.props.importId);
    if (isEdit) {
      UserImportActions.updateUserImport(
        this.props.importId,
        {
          email_introduction_part: this.state.emailIntroductionPart,
          email_preend_part: this.state.emailPreendPart,
        },
        USER_IMPORT_FORM_ID,
      );
    } else {
      UserImportActions.createUserImport(
        {
          source: this.state.source ?? '',
          send_emails_to: this.state.sendEmailsTo,
          email_introduction_part: this.state.emailIntroductionPart,
          email_preend_part: this.state.emailPreendPart,
          organization: this.props.organization.id,
        },
        USER_IMPORT_FORM_ID,
      );
    }
  },

  render() {
    const {
      currentMember,
      routes,
      importId,
      organization,
    } = this.props;

    const {
      userImport,
      loading,
      errors,
      isProcessing,
      source,
      sendEmailsTo,
      emailIntroductionPart,
      emailPreendPart,
      loadError,
    } = this.state;
    if (!currentMember.get('is_admin')) {
      router.navigate(routes.MEMBERS);
      return <Loading />;
    }

    const isEdit = Boolean(importId);
    const editable = !userImport?.isFinished() && !userImport?.isRunning();

    if (!userImport && isEdit) {
      if (loadError) {
        return <p>{loadError}</p>;
      }
      if (loading) {
        return <Loading />;
      }

      // Looks like import not found
      router.navigate(routes.MEMBERS_IMPORT);
      return <Loading />;
    }

    const willSendEmail = (
      sendEmailsTo !== undefined && sendEmailsTo !== DO_NOT_SEND_EMAILS
    );
    return (
      <div className='cub-UserImportForm-wrapper'>
        <Button
          onClick={() => router.navigate(routes.MEMBERS_IMPORT)}
          classNameModifier="cub-Button--back"
          text="Go Back To List"
        />
        <Form
          classNameModifier='cub-Form--userImport'
          error={errors.topError()}
          onSubmit={this.submit}
          isProcessing={isProcessing}
          disableWhenProcessing
        >
          <LabeledFileControl
            label='Import file'
            name='source'
            value={source}
            required
            onChange={this.onInputChange}
            // eslint-disable-next-line max-len
            acceptedMimeTypes='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
            supportedExtensions={['xlsx', 'xls']}
            error={errors.fieldError('source')}
            isPrivate
            uploadTo='user_import'
            allowedToUpdate={!isEdit}
          />
          <ImportFileFormat/>

          <div className='cub-UserImport-welcomeEmail'>
            <div className='cub-UserImport-sendEmailsTo'>
              <LabeledCombobox
                classNameModifier='cub-FormControl--sendEmailsTo'
                value={sendEmailsTo}
                required
                label='Send welcome email to'
                name='sendEmailsTo'
                data={[
                  {
                    text: 'Do not send welcome emails',
                    value: DO_NOT_SEND_EMAILS,
                  },
                  {
                    text: 'Created during this import only',
                    value: ONLY_NEW_USERS_EMAILS,
                  },
                  {
                    text: 'All non logged in and activated',
                    value: ALL_NOT_LOGGED_IN_USERS_EMAILS,
                  },
                ]}
                valueField='value'
                textField='text'
                onChange={this.onSendEmailsToChange}
                error={errors.fieldError('send_emails_to')}
                readOnly={isEdit}
              />
              {willSendEmail && (
                <PreviewEmail
                  organizationId={organization.id}
                  emailIntroductionPart={emailIntroductionPart}
                  emailPreendPart={emailPreendPart}
                />
              )}
            </div>
            <div className='cub-UserImport-emailParts'>
              {willSendEmail && (
                <>
                  <LabeledTextarea
                    classNameModifier='cub-FormControl--emailIntroductionPart'
                    name='emailIntroductionPart'
                    label='Email introduction part'
                    value={emailIntroductionPart}
                    onChange={editable ? this.onInputChange : null}
                    error={errors.fieldError('email_introduction_part')}
                  />
                  <LabeledTextarea
                    classNameModifier='cub-FormControl--emailPreendPart'
                    name='emailPreendPart'
                    label='Email preend part'
                    value={emailPreendPart}
                    onChange={editable ? this.onInputChange : null}
                    error={errors.fieldError('email_preend_part')}
                  />
                </>
              )}
            </div>
          </div>
          {editable && (
            <div className='cub-Form-Buttons'>
              <Button
                classNameModifier='cub-Button--launch'
                type='submit'
                isProcessing={isProcessing}
                text={isEdit ? 'Launch import' : 'Launch Preview'}
              />
            </div>
          )}
        </Form>
        {isEdit && (
          <ImportSummaryPreview userImport={userImport}/>
        )}
      </div>
    );
  },
});

export default UserImportForm;
