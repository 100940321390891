/* eslint-disable max-len */
import React from 'react';
import Modal from '../../basic/Modal';

export default class ImportFileFormat extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
    };
    this.onOpen = this.onOpen.bind(this);
    this.onClose = this.onClose.bind(this);
  }

  onOpen() {
    this.setState({ isOpen: true });
  }

  onClose() {
    this.setState({ isOpen: false });
  }

  render() {
    const { isOpen } = this.state;
    return (
      <>
      <a
        className='cub-UserImport-importFormatUrl'
        onClick={this.onOpen}>
          Import file format
      </a>
      <Modal title="User Import Source Format" onClose={this.onClose} isOpen={isOpen}>
        <div className='cub-SourceFormat'>
          <p>
            User import source file must be an Excel 2007+ workbook (.xlsx) that
            meets the following requirements:
          </p>

          <ol>
            <li>
              Data must be placed on an active sheet in a workbook. Active sheet is the
              one which was open when you saved a file last time. If workbook contains
              multiple sheets, all other sheets except active are ignored.
            </li>
            <li>
              First row on a sheet must contain column names. Possible column names and
              options are listed below.
            </li>
            <li>
              Either <code>Email</code> or <code>Username</code> column is required, all
              others are optional. Values in unknown columns are ignored.
            </li>
          </ol>

          <table className='cub-SourceFormat-table'>
            <thead>
              <tr>
                <th>Column name</th>
                <th>Possible values</th>
                <th>Comments</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Email</td>
                <td>valid email, up to 254 characters</td>
                <td>
                  User records are matched by <code>email</code>. If user is found by
                  email, it will be updated, otherwise a new user record will be
                  created.
                </td>
              </tr>
              <tr>
                <td>Username</td>
                <td>up to 60 characters, can't contain <code>@</code></td>
                <td>
                  Applied to new users only. If <code>username</code> is already taken,
                  a new available username will be automatically generated. If username
                  is not provided, it will be generated from email.
                </td>
              </tr>
              <tr>
                <td>First name</td>
                <td>up to 40 characters</td>
                <td rowspan="3">
                  <p>
                    For already existing users names will be updated only if current
                    stored values don't conflict with new ones. Examples:
                  </p>
                  <ul>
                    <li>
                      OK, UPDATE: <code>(blank)</code> -> <code>Alex Ford</code>
                      (both first and last name were previously unknown)
                    </li>
                    <li>
                      OK, UPDATE: <code>Alex</code> -> <code>Alex Ford</code>
                      (last name was unknown, first name matches)
                    </li>
                    <li>
                      CONFLICT, SKIP: <code>Alex</code> -> <code>Alexander S. Ford</code>
                      (skipping update because first name doesn't match)
                    </li>
                    <li>
                      OK, UPDATE:
                      <code>Alexander Ford</code> -> <code>Alexander S. Ford</code>
                      (middle name was previously unknown, first and last names match)
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td>Middle name</td>
                <td>up to 40 characters</td>
              </tr>
              <tr>
                <td>Last name</td>
                <td>up to 50 characters</td>
              </tr>
              <tr>
                <td>Gender</td>
                <td><code>male</code> or <code>female</code></td>
                <td rowspan="2">
                  For already existing users both <code>Gender</code> and
                  <code>Birth date</code> will be updated only if current stored values
                  are blank.
                </td>
              </tr>
              <tr>
                <td>Birth date</td>
                <td>"Date" cell format in Excel</td>
              </tr>
              <tr>
                <td>Position</td>
                <td>up to 200 characters</td>
                <td>
                  Assigns user to a position if organization is
                  created/found.
                  If the position doesn't exist, one will be created, with the public flag set to False.
                  The new position is added to the list of existing user positions.
                </td>
              </tr>
              <tr>
                <td>Is admin</td>
                <td>
                  <code>true</code>, <code>false</code>, <code>yes</code>,
                  <code>no</code>, <code>1</code> or <code>0</code>
                </td>
                <td>
                  Created or existing member will be organization admin if set to
                  one of positive values (true, yes, 1). If member exists and is admin
                  already, negative value (no, 0, false) won't disable admin rights.
                </td>
              </tr>
              <tr>
                <td>Personal ID</td>
                <td>up to 90 characters</td>
                <td>
                  Personal ID for created or existing member. Please notice that
                  existing personal id will be replaced with the provided one.
                </td>
              </tr>
              <tr>
                <td>Unit</td>
                <td>up to 200 characters</td>
                <td>
                  Unit for the member's position. If the member already has an existing
                  unit, it will be replaced with the one provided.
                </td>
              </tr>
              <tr>
                <td>Group</td>
                <td>up to 100 characters</td>
                <td>
                  Group for member. If a group does not exist it will be created. Multiple
                  groups can be provided in one cell separated by commas. To enter a group
                  with a comma in the name, escape the comma with the "\" character.
                  For example, the value "Patrol\, Reserve, Tactical" will be interpreted
                  as two groups: "Patrol, Reserve" and "Tactical".
                  If the provided group is not in the current list of user groups, the new group will
                  be added to the list, and the existing groups will remain.
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </Modal>
      </>
    );
  }
}
