import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import React from 'react';
import _ from 'underscore';
import SearchGrid from '../basic/SearchGrid';

const ImportGrid = createReactClass({

  propTypes: {
    className: PropTypes.string,
    organization: PropTypes.object,
    imports: PropTypes.array,
    routes: PropTypes.object,
  },

  getDefaultProps() {
    return {
      filters: {},
    };
  },

  getInitialState() {
    return { q: '' };
  },

  onSearchChange(search) {
    this.setState({ q: search });
  },

  getFilters() {
    return {
      organization: this.props.organization.get('id'),
      q: this.state.q,
    };
  },

  displayFields(userImport) {
    const processed = userImport.get('processed') ||
    userImport.get('preview_generated');
    let source = '';
    if (userImport.get('source')) {
      source = new URL(userImport.get('source')).pathname.split('/');
      source = source[source.length - 1];
    }

    return [
      source,
      userImport.getState(),
      processed ? processed.toLocaleString() : '',
    ];
  },

  links(userImport) {
    return this.props.routes.IMPORT.makeUrl(userImport.id);
  },

  render() {
    return (
      <SearchGrid
        className={this.props.className}
        cols={[
          'Source file',
          'Status',
          'Last Processed time',
        ]}
        displayFields={this.displayFields}
        links={this.links}
        models={this.props.imports}
        endpoint="user_imports"
        filters={this.getFilters()}
        value={this.getFilters().q}
        pageSize={20}
        onSearchChange={this.onSearchChange}
      />
    );
  },
});

export default ImportGrid;
