import _ from 'underscore';
import React from 'react';
import Modal from '../../basic/Modal';
import UserImportActions from '../../../actions/UserImportActions';
import Loading from '../../basic/Loading';
import PropTypes from 'prop-types';
import Button from '../../basic/Button';

export default class PreviewEmail extends React.Component {
  constructor(props) {
    super(props);
    this.onOpenPreview = this.onOpenPreview.bind(this);
    this.onClosePreview = this.onClosePreview.bind(this);

    this.state = {
      isOpen: false,
      loading: false,
      error: '',
    };
  }

  onOpenPreview() {
    this.setState({ isOpen: true, loading: true, error: '' });
    const {
      organizationId,
      emailIntroductionPart,
      emailPreendPart,
    } = this.props;
    UserImportActions.getPreviewEmail(
      organizationId,
      emailIntroductionPart,
      emailPreendPart,
      (resp) => {
        this.setState({ loading: false, previewEmail: resp.rendered_email });
      },
      () => this.setState({
        loading: false,
        error: (
          'Some error happened, try again later or contact administrator'
        ),
      }),
    );
  }

  onClosePreview() {
    this.setState({ isOpen: false });
  }

  render() {
    const {
      isOpen, previewEmail, loading, error
    } = this.state;

    return (
      <>
        <Button
          classNameModifier="cub-Button--previewEmail"
          type="button"
          showIcon={false}
          onClick={this.onOpenPreview}
          text="Preview Email"
        />
        <Modal
          title="Preview Email"
          isOpen={isOpen}
          onClose={this.onClosePreview}
        >
          {error && <p>{error}</p>}
          {loading && !error ? <Loading /> : (
            <div
              className='cub-Button--previewEmailBlock'
              dangerouslySetInnerHTML={{ __html: previewEmail }}
            />
          )}
        </Modal>
      </>
    );
  }
}

PreviewEmail.propTypes = {
  organizationId: PropTypes.string,
  emailIntroductionPart: PropTypes.string,
  emailPreendPart: PropTypes.string,
};
