import _ from 'underscore';
import FormActions from './FormActions';
import { logger } from '../utils/Utils';
import CubApi from '../api/CubApi';
import UserStore from '../stores/UserStore';
import AppDispatcher from '../dispatcher/AppDispatcher';
import {
  USER_IMPORT_UPDATED,
} from '../constants/ActionTypes';

const UserImportActions = {
  createUserImport(data, formId, onSuccess, onError) {
    FormActions.submitUserForm(
      formId,
      'user_imports',
      data,
      onSuccess,
      onError,
    );
  },

  updateUserImport(importId, data, formId, onSuccess, onError) {
    FormActions.submitUserForm(
      formId,
      `user_import/${importId}`,
      data,
      onSuccess,
      onError,
    );
  },

  getUserImport(importId, onSuccess, onError) {
    CubApi.get(`user_import/${importId}`,
      { apiKey: UserStore.token(true) },
      (...args) => {
        UserImportActions.userImportLoaded(...args);
        if (onSuccess) onSuccess(...args);
      },
      onError || logger.warn);
  },

  getUserImportLogs(importId, onSuccess, onError) {
    CubApi.get('user_import_logs/',
      { apiKey: UserStore.token(true), data: { user_import: importId } },
      (...args) => {
        if (onSuccess) onSuccess(...args);
      },
      onError || logger.warn);
  },

  getPreviewEmail(
    organization,
    emailIntroductionPart,
    emailPreendPart,
    onSuccess,
    onError,
  ) {
    CubApi.post('user_import/email_preview/',
      {
        apiKey: UserStore.token(true),
        data: {
          organization,
          email_introduction_part: emailIntroductionPart ?? '',
          email_preend_part: emailPreendPart ?? '',
        }
      },
      (...args) => {
        if (onSuccess) onSuccess(...args);
      },
      onError || logger.warn);
  },

  userImportLoaded(response) {
    AppDispatcher.handleServerAction({
      actionType: USER_IMPORT_UPDATED,
      response,
    });
  },
};

export default UserImportActions;
