import React from 'react';
import UserImportActions from '../../../actions/UserImportActions';
import Loading from '../../basic/Loading';
import Modal from '../../basic/Modal';
import PropTypes from 'prop-types';

export default class ImportSummary extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      logs: [],
      loading: false,
      error: '',
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.isOpen && prevProps.isOpen !== this.props.isOpen) {
      this.setState({ loading: true, error: '' });
      UserImportActions.getUserImportLogs(
        this.props.importId,
        (resp) => {
          this.setState({ loading: false, logs: resp });
        },
        () => this.setState({
          loading: false,
          error: (
            'Some error happened, try again later or contact administrator'
          ),
        }),
      );
    }
  }

  render() {
    const { isOpen, onClose } = this.props;
    const { logs, loading, error } = this.state;

    const groupedLogs = {};
    logs.forEach((log) => {
      if (!groupedLogs[log.model_name]) {
        groupedLogs[log.model_name] = {};
      }
      if (!groupedLogs[log.model_name][log.event]) {
        groupedLogs[log.model_name][log.event] = [];
      }

      groupedLogs[log.model_name][log.event].push(log);
    });

    return (
      <Modal title="Import summary" isOpen={isOpen} onClose={onClose}>
        {loading && <Loading />}
        {error && <p>{error}</p>}
        {!error &&
        Object.entries(groupedLogs).map(([modelName, groupedEventLogs]) => (
          Object.entries(groupedEventLogs).map(([eventName, eventLogs]) => (
            <div
              className='cub-ImportSummary-tableWrapper'
              key={modelName + eventName}
            >
              <h3 className='cub-ImportSummary-header'>
                {modelName} - {eventName}
              </h3>
              <table className="cub-ImportSummary-table">
                <thead>
                  <tr>
                    <th>Excel cell</th>
                    <th>Value</th>
                    <th>Comment</th>
                  </tr>
                </thead>
                <tbody>
                  {eventLogs.map((log, i) => (
                    <tr key={i} onClick={this.onOpenFullSummary}>
                      <td>{log.column}{log.row}</td>
                      <td>{log.value}</td>
                      <td>{log.comment}</td>
                    </tr>
                  ))}
                  </tbody>
              </table>
            </div>
          ))))
        }
      </Modal>
    );
  }
}

ImportSummary.propTypes = {
  importId: PropTypes.string,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};
