import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import React from 'react';
import _ from 'underscore';
import DataStore from '../../stores/DataStore';
import MemberActions from '../../actions/MemberActions';
import MemberGrid from './MemberGrid';
import NavLink from '../basic/NavLink';
import storeListenerMixin from '../../mixins/storeListenerMixin';
import { MEMBER_PERMISSIONS } from '../../constants/FormIds';
import Button from '../basic/Button';
import Tabs from '../basic/Tabs';
import OrganizationSearch from '../fields/OrganizationSearch';
import UserActions from '../../actions/UserActions';
import OrganizationActions from '../../actions/OrganizationActions';

/* eslint-disable react/prefer-es6-class */
const MemberList = createReactClass({
  // eslint-disable-next-line react/no-unused-class-component-methods
  propTypes: {
    currentMember: PropTypes.object,
    organization: PropTypes.object,
    onChange: PropTypes.func,
    route: PropTypes.object,
    routes: PropTypes.object,
  },

  /* eslint-disable react/no-unused-class-component-methods,react/sort-comp */
  mixins: [storeListenerMixin(DataStore)],

  getStateFromStores() {
    const org = DataStore.selectedTrustingOrg() || this.props.organization;
    const { activeMembers, invitedMembers } = this.getMembersList(org);
    return {
      activeMembers,
      invitedMembers,
    };
  },
  /* eslint-enable react/no-unused-class-component-methods,react/sort-comp */

  componentDidMount() {
    const org = DataStore.selectedTrustingOrg() || this.props.organization;
    if (org && org.id !== this.props.currentMember.get('organization').id) {
      _.defer(() => (
        OrganizationActions.getOrganization(org.id, 'active_members')
      ));
    }
  },

  onOrgChange(event) {
    const org = event.value;
    if (org.related == null) {
      return;
    }
    OrganizationActions.getOrganization(org.get('id'), 'active_members');
    const members = this.getMembersList(org);
    this.setState({
      activeMembers: members.activeMembers,
      invitedMembers: members.invitedMembers,
    });
    if (typeof this.props.onChange === 'function') {
      this.props.onChange(org);
    }
    UserActions.selectTrustingOrg(org);
  },

  getMembersList(org) {
    const activeMembers = [];
    const invitedMembers = [];
    const members = org.related.member;
    members.forEach((member) => {
      if (member.get('is_active')) {
        if (!member.get('user').get('last_login')) {
          invitedMembers.push(member);
        } else {
          activeMembers.push(member);
        }
      }
    });

    return { activeMembers, invitedMembers };
  },

  setActive(member, active, e) {
    MemberActions.setMemberPermissions(
      member, { is_active: active }, MEMBER_PERMISSIONS,
    );
    e.preventDefault();
  },

  memberButtons(member) {
    const btns = [];
    const link = this.props.routes.MEMBER.makeUrl(member.id);
    btns.push(
      <NavLink
        href={link}
        className="cub-Button cub-Button--edit"
        key="edit"
      >
        <span className="cub-Button-icon" />
        Edit
      </NavLink>,
    );
    // can't deactivate self
    if (member.get('user').id !== this.props.currentMember.get('user').id) {
      /* eslint-disable react/jsx-no-bind */
      if (member.get('is_active')) {
        btns.push(
          <Button
            classNameModifier="cub-Button--deactivate"
            key="deactivate"
            onClick={this.setActive.bind(this, member, false)}
            text="Deactivate"
          />,
        );
      } else {
        btns.push(
          <Button
            classNameModifier="cub-Button--activate"
            key="activate"
            onClick={this.setActive.bind(this, member, true)}
            text="Activate"
          />,
        );
      }
      /* eslint-disable react/jsx-no-bind */
    }
    return btns;
  },

  render() {
    const routes = this.props.routes;
    const tabsData = [
      {
        id: routes.MEMBERS_ACTIVE.name,
        name: 'activeMembers',
        title: 'Active members',
        route: routes.MEMBERS_ACTIVE,
        count: null,
      },
      {
        id: routes.MEMBERS_INVITED.name,
        name: 'invitedMembers',
        title: 'Invited members',
        route: routes.MEMBERS_INVITED,
        count: null,
      },
    ];

    const org = this.props.organization;
    const { joined, invited } = org.membersCount();
    tabsData[0].count = joined;
    tabsData[1].count = invited;

    let members;
    let filters = { is_active: true, order_by: 'user__last_name' };
    if (this.props.route === routes.MEMBERS_INVITED) {
      members = this.state.invitedMembers;
      filters = _.extend({}, filters, { invited: true });
    } else {
      members = this.state.activeMembers;
      filters = _.extend({}, filters, { invited: false });
    }

    const user = DataStore.currentUser();
    const showOrgControl = user.isTrustedAdmin();
    // TODO: Uncomment the following line and remove two preceding lines when
    //  risk pool admins no longer need to be org admins (HS-2905).
    // const showOrgControl = this.props.currentMember.get('is_trusted_admin');

    // Add organization ID to the 'Add member' button URL if the selected
    // trusting org doesn't match the current one and member creation is not
    // handled by LID.
    let addNewMemberRoute = routes.MEMBERS_NEW;
    const newMemberLinkProps = {};
    if (org.id !== this.props.currentMember.get('organization').id) {
      addNewMemberRoute = routes.MEMBERS_NEW_IN_ORG;
      newMemberLinkProps.org_id = org.id;
    }

    return (
      <div className="cub-GridList cub-GridList--members">
        <div className="cub-GridList-controls">
          <div className="cub-Row cub-Util-flexJustifyBetween">
            <div className="cub-Row-column cub-Row-column--65">
              {showOrgControl && (
                <OrganizationSearch
                  label={null}
                  value={org.toFullJson()}
                  onChange={this.onOrgChange}
                  searchManagedTrusting
                />
              )}
            </div>
            <div className="cub-Row-column">
              <NavLink
                className="cub-Button cub-Button--imageUpload"
                route={routes.MEMBERS_IMPORT}
              >
                <span className="cub-Button-icon" />
                Import members
              </NavLink>
            </div>
            <div className="cub-Row-column">
              <NavLink
                className="cub-Button cub-Button--add"
                route={addNewMemberRoute}
                {...newMemberLinkProps}
              >
                <span className="cub-Button-icon" />
                Add member
              </NavLink>
            </div>
          </div>
        </div>
        <Tabs
          classNameModifier="cub-Tabs--members"
          options={tabsData}
          active={this.props.route.name}
        />

        <MemberGrid
          className="cub-Grid cub-Grid--memberList"
          routes={routes}
          members={members}
          filters={filters}
          btns={this.memberButtons}
          currentMember={this.props.currentMember}
          organization={this.props.organization}
        />

      </div>
    );
  },
});

export default MemberList;
