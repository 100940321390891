import _ from 'underscore';
import PropTypes from 'prop-types';
import React from 'react';
import ImportSummary from './ImportSummary';
import Loading from '../../basic/Loading';

const StatusMap = {
  preview: 'Preview generated',
  running: 'Running',
  finished: 'Finished',
  // eslint-disable-next-line max-len
  failed: 'Import failed. Try again. If nothing changes, contact the administrator',
};

export default class ImportSummaryPreview extends React.Component {
  constructor(props) {
    super(props);
    this.onOpenFullSummary = this.onOpenFullSummary.bind(this);
    this.onCloseFullSummary = this.onCloseFullSummary.bind(this);

    this.state = {
      isOpen: false,
    };
  }

  onOpenFullSummary() {
    this.setState({ isOpen: true });
  }

  onCloseFullSummary() {
    this.setState({ isOpen: false });
  }

  render() {
    const { isOpen } = this.state;
    const { userImport } = this.props;

    const previewGenerationStarted = userImport.get(
      'preview_generation_started'
    );
    const previewGenerated = userImport.get('preview_generated');
    const processStarted = userImport.get('process_started');
    const processed = userImport.get('processed');

    const logSummary = userImport.get('log_summary');
    const isRunning = userImport.isRunning();

    const emptyLogsMessage = isRunning ? <Loading/> : (
      <p>
        This import didn't result in changes to the database
      </p>
    );

    return (
      <div className="cub-ImportSummary">
        <h3>Import summary</h3>
        <p>
          Status: <strong>{StatusMap[userImport.getState()]}</strong>
        </p>
        {previewGenerationStarted && (
          <p>Preview generation started:
            {previewGenerationStarted.toLocaleString()}
          </p>
        )}
        {previewGenerated && (
          <p>Preview generated:
            {previewGenerated.toLocaleString()}
          </p>
        )}
        {processStarted && (
          <p>Processing started:
            {processStarted.toLocaleString()}
          </p>
        )}
        {processed && <p>Processed: {processed.toLocaleString()}</p>}
        {_.isEmpty(logSummary) ? emptyLogsMessage : (
            <table
              className="cub-ImportSummary-table
              cub-ImportSummary-table--preview"
            >
              <thead>
                <tr>
                  <th>Model</th>
                  <th>Errors</th>
                  <th>Warnings</th>
                  <th>Created</th>
                  <th>Modified</th>
                </tr>
              </thead>
              <tbody>
                {Object.entries(logSummary).map(([modelName, logs], i) => (
                  <tr key={i} onClick={this.onOpenFullSummary}>
                    <td>{modelName ?? 0}</td>
                    <td>{logs.errors ?? 0}</td>
                    <td>{logs.warnings ?? 0}</td>
                    <td>{logs.created ?? 0}</td>
                    <td>{logs.modified ?? 0}</td>
                  </tr>
                ))}
                </tbody>
            </table>
        )
        }
        <ImportSummary
          importId={userImport.id}
          isOpen={isOpen}
          onClose={this.onCloseFullSummary}
        />
      </div>
    );
  }
}

ImportSummaryPreview.propTypes = {
  userImport: PropTypes.object,
};
