import React, { Component } from 'react';
import Button from './Button';
import PropTypes from 'prop-types';

class Modal extends Component {
  constructor(props) {
    super(props);
  }

  closeModal = () => {
    if (this.props.onClose) {
      this.props.onClose();
    }
  };

  render() {
    const { isOpen, title, children } = this.props;

    if (!isOpen) {
      return null;
    }

    return (
      <div className="cub-Modal-overlay">
        <div className="cub-Modal-contentWrapper">
          <h2>{title}</h2>
          <div className="cub-Modal-content">{children}</div>
          <Button
            classNameBase="cub-Button"
            classNameModifier="cub-Button--cancel"
            onClick={this.closeModal}
          />
        </div>
      </div>
    );
  }
}

Modal.propTypes = {
  onClose: PropTypes.func,
  title: PropTypes.string,
  isOpen: PropTypes.bool,
};

export default Modal;
