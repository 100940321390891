import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import React from 'react';
import _ from 'underscore';
import DataStore from '../../stores/DataStore';
import NavLink from '../basic/NavLink';
import storeListenerMixin from '../../mixins/storeListenerMixin';
import ImportGrid from './ImportGrid';

/* eslint-disable react/prefer-es6-class */
const ImportList = createReactClass({
  // eslint-disable-next-line react/no-unused-class-component-methods
  propTypes: {
    currentMember: PropTypes.object,
    organization: PropTypes.object,
    onChange: PropTypes.func,
    route: PropTypes.object,
    routes: PropTypes.object,
  },

  /* eslint-disable react/no-unused-class-component-methods,react/sort-comp */
  mixins: [storeListenerMixin(DataStore)],

  getStateFromStores() {
    return {
      imports: DataStore.userimport.models,
    };
  },

  render() {
    const routes = this.props.routes;

    return (
      <div className="cub-GridList cub-GridList--userImports">
        <div className="cub-GridList-controls">
          <div className="cub-Row cub-Util-flexJustifyFlexEnd">
            <div className="cub-Row-column">
              <NavLink
                className="cub-Button cub-Button--add"
                route={routes.IMPORT_NEW}
              >
                <span className="cub-Button-icon" />
                Start new import
              </NavLink>
            </div>
          </div>
        </div>
        <ImportGrid
          className="cub-Grid cub-Grid--userImportList"
          routes={routes}
          imports={this.state.imports}
          currentMember={this.props.currentMember}
          organization={this.props.organization}
        />

      </div>
    );
  },
});

export default ImportList;
