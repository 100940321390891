import PropTypes from 'prop-types';
import React from 'react';
import Error from '../basic/Error';
import FileUploadForm from '../forms/FileUploadForm';

export default class LabeledFileControl extends React.Component {
  constructor(props) {
    super(props);

    const state = {
      value: this.props.value,
    };
    this.state = state;
    this.validate = this.validate.bind(this);
    this.onFileUploaded = this.onFileUploaded.bind(this);
    this.onRemove = this.onRemove.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.value !== prevState.value) {
      if (this.props.onChange) {
        let value = this.state.value;
        if (value) {
          const url = new URL(this.state.value);
          value = url.pathname;
        }
        this.props.onChange({
          target: {
            name: this.props.name,
            value: value,
          },
        });
      }
    }
  }

  onFileUploaded(url) {
    this.setState({ value: url });
  }

  onRemove() {
    this.setState({ value: null });
  }

  validate() {
    if (this.props.required && !this.state.value) {
      return 'This field is required.';
    }
    return null;
  }

  render() {
    const {
      allowedToUpdate,
      value,
      label,
      required,
      acceptedMimeTypes,
      supportedExtensions,
      isPrivate,
      uploadTo,
      name,
      error
    } = this.props;

    let removeBtnText = null;
    let uploadBtnText = 'Upload file';
    if (value && allowedToUpdate) {
      removeBtnText = 'Remove file';
      uploadBtnText = 'Change file';
    }

    let urlToShow = '';
    if (this.state.value) {
      urlToShow = new URL(this.state.value).pathname.split('/');
      urlToShow = urlToShow[urlToShow.length - 1];
    }

    return (
      <div className="cub-FormGroup cub-FormGroup--file">
        <label className="cub-Label cub-Label--file">
          {label}
          {required && <span className="cub-Label-asterisk">*</span>}
        </label>
        {this.state.value &&
          <a href={this.state.value} target='_blank'>{urlToShow}</a>
        }
        {allowedToUpdate && (
          <FileUploadForm
            apiEndpoint="file/upload"
            altText="file"
            onRemove={this.onRemove}
            onS3UploadDone={this.onFileUploaded}
            src={this.state.value}
            acceptedMimeTypes={acceptedMimeTypes}
            supportedExtensions={supportedExtensions}
            isPrivate={isPrivate}
            uploadTo={uploadTo}
            uploadBtnText={uploadBtnText}
            removeBtnText={removeBtnText}
          />
        )}
        <Error data-field={name}>
          {error}
        </Error>
      </div>
    );
  }
}

LabeledFileControl.propTypes = {
  acceptedMimeTypes: PropTypes.string,
  supportedExtensions: PropTypes.array,
  error: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
  required: PropTypes.bool,
  isPrivate: PropTypes.bool,
  uploadTo: PropTypes.string,
  allowedToUpdate: PropTypes.bool,
};

LabeledFileControl.defaultProps = {
  allowedToUpdate: true,
};
