export const IMPERSONATE = 'IMPERSONATE';
export const FORM_SUBMITTED = 'FORM_SUBMITTED';
export const FORM_SUCCESS = 'FORM_SUCCESS';
export const FORM_RESET = 'FORM_RESET';
export const FORM_ERROR = 'FORM_ERROR';
export const FORM_CANCELLED = 'FORM_CANCELLED';
export const CLEAR_FORM_ERROR = 'CLEAR_FORM_ERROR';
export const CLEAR_ALL_FORM_ERRORS = 'CLEAR_ALL_FORM_ERRORS';

export const APP_INITIALIZED = 'APP_INITIALIZED';
export const ROUTER_INITIALIZED = 'ROUTER_INITIALIZED';
export const URL_CHANGED = 'URL_CHANGED';
export const URL_CHANGE_REQUESTED = 'URL_CHANGE_REQUESTED';

export const USER_LOGOUT = 'USER_LOGOUT';
export const USER_UPDATED = 'USER_UPDATED';
export const USER_LOAD_ERROR = 'USER_LOAD_ERROR';

export const PASSWORD_RESET_TOKEN_CHECKED = 'PASSWORD_RESET_TOKEN_CHECKED';
export const PASSWORD_RESET_TOKEN_ERROR = 'PASSWORD_RESET_TOKEN_ERROR';

export const EMAIL_CONFIRMATION_SUCCESS = 'EMAIL_CONFIRMATION_SUCCESS';
export const EMAIL_CONFIRMATION_ERROR = 'EMAIL_CONFIRMATION_ERROR';

export const IDP_CONFIRMATION_SUCCESS = 'IDP_CONFIRMATION_SUCCESS';
export const IDP_CONFIRMATION_ERROR = 'IDP_CONFIRMATION_ERROR';

export const USER_SET_CURRENT_ORGANIZATION = 'USER_SET_CURRENT_ORGANIZATION';
export const USER_SELECT_TRUSTING_ORG = 'USER_SELECT_TRUSTING_ORG';
export const USER_REMOVE_PHOTO = 'USER_REMOVE_PHOTO';

export const CHUNKED_LOADING_STARTED = 'CHUNKED_LOADING_STARTED';
export const CHUNK_LOADED = 'CHUNK_LOADED';

export const ORGANIZATION_UPDATED = 'ORGANIZATION_UPDATED';

export const MEMBER_DELETED = 'MEMBER_DELETED';
export const MEMBER_UPDATED = 'MEMBER_UPDATED';
export const MEMBER_GOT = 'MEMBER_GOT';

export const GROUP_DELETED = 'GROUP_DELETED';
export const GROUP_UPDATED = 'GROUP_UPDATED';

export const SITE_LOADED = 'SITE_LOADED';

export const LEAD_FORM_UPDATED = 'LEAD_FORM_UPDATED';

export const ORDER_UPDATED = 'ORDER_UPDATED';

export const ORDER_INVOICE_AUTOLOGIN_FAILED = 'ORDER_INVOICE_AUTOLOGIN_FAILED';
export const REGISTER_FOR_INVOICE_FAILED = 'REGISTER_FOR_INVOICE_FAILED';

export const GOT_XDOMAIN_COOKIES = 'GOT_XDOMAIN_COOKIES';
export const GOT_XSSSO_GROUP = 'GOT_XSSSO_GROUP';

export const RESET_USERNAME_LOOKUP = 'RESET_USERNAME_LOOKUP';

export const USER_IMPORT_UPDATED = 'USER_IMPORT_UPDATED';
